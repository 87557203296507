<template>
  <div class="detail-package pt-3 pt-md-0">
    <!-- <section
      class="px-3 pt-md-3 pb-0 px-md-5 pt-md-5 pb-md-0 mt-0 mt-md-0 mt-lg-0"
    > -->
    <NavbarOne
      class="card-top d-md-none d-lg-none"
      id="card-top"
      showNavbar
      showCart
      showCartDesktop
      showSearch
    >
    </NavbarOne>
    <!-- </section> -->
    <page-not-found class="mx-auto mt-3 mt-md-5" v-if="detailPackage == null" />

    <v-card class="ma-3 mt-16 ma-md-5" v-else>
      <CustomSection>
        <!-- Detail Package {{ $route.params.id }} -->
        <HeadingCard
          class="mb-2 pt-3"
          color="soft-blue"
          bold
          :title="detailPackage.name"
        />
        <h4 class="price">{{ formatPrice(defaultPrice) }}</h4>

        <v-row>
          <v-col cols="12" md="9">
            <p class="description mb-0" style="color: #1f3c87">
              {{
                detailPackage[`description_${$i18n.locale}`] != null &&
                detailPackage[`description_${$i18n.locale}`] != ""
                  ? detailPackage[`description_${$i18n.locale}`]
                  : "-"
              }}
            </p>
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="mb-2 text-right"
            v-if="
              checkBranch && !detailPackage.subscription && checkAvailableGender
            "
          >
            <c-button
              rounded
              outline
              small
              v-if="checkActive(detailPackage, null)"
              @click="
                detailPackage.subscription
                  ? removeCart(detailPackage, detailPackage.id)
                  : removeCartPackage(detailPackage)
              "
            >
              {{ $t("page.cart.remove_from_cart") }}
            </c-button>
            <c-button
              class="ml-3"
              rounded
              small
              @click="
                detailPackage.subscription
                  ? addCart(detailPackage, detailPackage.id)
                  : addCartPackage(detailPackage)
              "
              v-else
            >
              {{ $t("page.cart.add_to_cart") }}
            </c-button>
          </v-col>
        </v-row>

        <v-expansion-panels
          v-model="panel"
          multiple
          accordion
          class="test-list-package"
        >
          <v-expansion-panel>
            <v-expansion-panel-header class="header-title-acc">
              <p>{{ $t("page.buy_package.package_detail") }}</p>
              <template v-slot:actions>
                <v-icon color="primary"> mdi-menu-down </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="content-title-acc">
              <v-col cols="12">
                <v-row
                  class="row-button"
                  v-if="
                    detailPackage.list_test != undefined &&
                    detailPackage.list_test.length > 0
                  "
                >
                  <v-col
                    cols="12"
                    md="6"
                    v-for="(list, index) in detailPackage.list_test"
                    :key="index"
                    style="padding: 5px"
                  >
                    <v-dialog v-model="dialog[list.id]" width="500px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          style="color: white"
                          v-bind="attrs"
                          v-on="on"
                          block
                        >
                          <p class="btn-list">
                            {{
                              $i18n.locale == "id"
                                ? list.name
                                : list[`name_${$i18n.locale}`]
                            }}
                          </p>
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="title-dialog">{{
                            $i18n.locale == "id"
                              ? list.name
                              : list[`name_${$i18n.locale}`]
                          }}</span>
                        </v-card-title>

                        <v-card-text class="content-dialog">
                          {{ list[`description_${$i18n.locale}`] }}
                        </v-card-text>

                        <v-divider></v-divider>
                        <accordion
                          class="accordion-dialog"
                          :title="$t('page.buy_package.examination_benefits')"
                        >
                          <template v-slot:body>
                            <p class="paragraph-dialog mb-0">
                              {{ list[`benefit_${$i18n.locale}`] }}
                            </p>
                          </template>
                        </accordion>
                        <v-divider></v-divider>
                        <accordion
                          class="accordion-dialog"
                          :title="$t('page.buy_package.patient_preparation')"
                        >
                          <template v-slot:body>
                            <p class="paragraph-dialog mb-0">
                              {{
                                $i18n.locale == "en"
                                  ? list.preparation
                                  : list[`preparation_${$i18n.locale}`]
                              }}
                            </p>
                          </template>
                        </accordion>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            class="btn-close"
                            text
                            @click="dialog[list.id] = false"
                          >
                            {{ $t("close") }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-row>
                <p class="mb-0 description" v-else>*{{ $t("empty_data") }}</p>
              </v-col>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-divider class="line-title"></v-divider>

          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header class="header-title-acc">
                <p>{{ $t("page.buy_package.purpose") }}</p>
                <template v-slot:actions>
                  <v-icon color="primary"> mdi-menu-down </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="content-title-prep">
                <template>
                  <p class="text-justify mb-0 mr-3">
                    {{
                      detailPackage[`benefit_${$i18n.locale}`] != null &&
                      detailPackage[`benefit_${$i18n.locale}`] != ""
                        ? detailPackage[`benefit_${$i18n.locale}`]
                        : "-"
                    }}
                  </p>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-expansion-panels>
        <v-divider class="mt-2"></v-divider>
        <HeadingCard
          class="my-2"
          color="soft-blue"
          bold
          :title="$t('page.buy_package.related_packages')"
        />

        <v-row dense>
          <v-col
            cols="12"
            md="6"
            class="pa-2"
            v-for="(relatedPackage, index) of detailPackage.related_package"
            :key="index"
          >
            <card-package
              :action="paramBranchSLug != '' && paramBranchSLug != null"
              :plant="
                paramBranchSLug != '' && paramBranchSLug != null
                  ? paramBranchSLug
                  : ''
              "
              :data="relatedPackage"
              :title="relatedPackage.name"
              :sub_title="`IDR ${
                relatedPackage.prices != null &&
                relatedPackage.prices.length !== 0
                  ? formatPrice(relatedPackage.prices[0].amount)
                  : '0'
              }`"
              :desc="`${relatedPackage.total_products} ${$t('test_covered')}`"
              @detail="openWarn($t('branch_cannot_empty'))"
            />
            <!-- <Card shadow roundedMd class="h-full">
              <template v-slot:body>
                <card-test
                  :title="relatedPackage.name"
                  :discountPrice="
                    formatPrice(
                      relatedPackage.prices.length > 0
                        ? relatedPackage.prices[0].amount
                        : 0
                    )
                  "
                  :subTitle="`${relatedPackage.total_products} ${$t(
                    'test_covered'
                  )}`"
                >
                  <template v-slot:footer>
                    <v-row class="row" dense>
                      <v-col class="text-left" cols="6">
                        <localized-link
                          :to="`/patient/buy-package/${relatedPackage.id}?plant=${paramBranchSLug}`"
                        >
                          <v-btn class="button" outlined text>
                            {{ $t("detail") }}
                          </v-btn>
                        </localized-link>
                      </v-col>
                      <v-col class="text-right" cols="6">
                        <localized-link
                          :to="`/patient/order-package/${relatedPackage.id}?plant=${paramBranchSLug}`"
                        >
                          <v-btn class="button" outlined text>
                            {{ $t("book_now") }}
                          </v-btn>
                        </localized-link>
                      </v-col>
                    </v-row>
                  </template>
                </card-test>
              </template>
            </Card> -->
          </v-col>
          <!-- </v-col> -->
        </v-row>
        <DialogMenu
          :tampilkan="dialogMenu"
          @toggleDialog="dialog => toggleDialogMenu(dialog)"
        />
      </CustomSection>
    </v-card>
  </div>
</template>

<script>
import CustomSection from "@/components/v2/section/index.vue";
import HeadingCard from "@/components/Heading/HeadingCard.vue";
import Accordion from "@/components/v2/accordion/index.vue";
import CButton from "@/components/v2/button/index.vue";
import CardPackage from "@/components/v3/cards/card-package.vue";

import API from "@/service/api_service";
import Auth from "@/service/auth_service";
import DialogMenu from "@/components/v2/dialog/dialogMenu.vue";
import NavbarOne from "@/components/v2/topbar/navbar_one.vue";
import PageNotFound from "@/components/v3/elements/page_not_found.vue";

export default {
  components: {
    CustomSection,
    HeadingCard,
    Accordion,
    CButton,
    CardPackage,
    DialogMenu,
    NavbarOne,
    PageNotFound,
  },
  data: () => ({
    paramBranchSLug: "",
    dialogMenu: false,
    showCart: {
      type: Boolean,
      default: false,
    },
    showCartDesktop: {
      type: Boolean,
      default: false,
    },
    panel: [0],
    dialog: {},
    detailPackage: null,
    defaultPrice: "",
    notFound: false,
  }),
  created() {
    this.getDetailPackage();
    this.paramBranchSLug = this.$route.query.plant;
  },
  computed: {
    checkBranch() {
      return this.$route.query.plant != "" &&
        this.$route.query.plant != null &&
        this.$route.query.plant != undefined
        ? true
        : false;
    },
    checkAvailableGender() {
      if (
        this.detailPackage.gender == null ||
        this.detailPackage.gender == "All"
      ) {
        return true;
      } else {
        return (
          this.$store.state.profileData.person.gender.name.toLowerCase() ==
          this.detailPackage.gender.toLowerCase()
        );
      }
    },
  },
  methods: {
    checkActive(item, deal) {
      if (typeof item.subscription === "undefined") {
        if (
          this.$store.state.cart.length > 0 &&
          this.$store.state.cart[0].plant != undefined
        ) {
          if (this.paramBranchSLug == this.$store.state.cart[0].plant.id) {
            return this.$store.state.cart.findIndex(
              res =>
                res.package != null &&
                res.package.id == item.id &&
                res.deal != null &&
                res.deal.id == deal.id
            ) < 0
              ? false
              : true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        if (
          this.$store.state.cart.length > 0 &&
          this.$store.state.cart[0].plant != undefined
        ) {
          if (this.paramBranchSLug == this.$store.state.cart[0].plant.id) {
            return this.$store.state.cart.findIndex(
              res => res.package != null && res.package.id == item.id
            ) < 0
              ? false
              : true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    },
    addPackageNonSubs(data) {
      this.$store.commit("updateCart", [...this.$store.state.cart, data]);
      const dataReady = [
        {
          plant_id: data.plant.id,
          package_id: data.package.id,
          deal_id: null,
          from_package: false,
          product_id: null,
          qty: 1,
        },
      ];
      API.post(
        `${process.env.VUE_APP_API_TRUST}cart/store`,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
        dataReady
      ).then(res => {
        if (res.statusCode == 200) {
          this.updateCartApi();
        }
      });
    },
    addCart(data, product_id) {
      if (this.checkBranch) {
        const checkCart = this.$store.state.cart.some(
          data => data.package != null && data.package.id == product_id
        );
        if (checkCart) {
          this.$store.state.cart.forEach(item => {
            if (item.package.id == product_id) {
              this.removeCartAllPackage(item);
            }
          });
        }
        var addCartTemp = {};
        if (data.from_deal) {
          // const indexAvailablePackage = data.available_packages.findIndex(
          //   res => res.id == product_id
          // );
          addCartTemp = {
            from_package: false,
            qty: 1,
            plant: {
              id: this.branch,
            },
            product: null,
            package: {
              id: product_id,
              name: data.name,
              prices: [
                {
                  amount_before_deal: data.amount_before_deal,
                  amount_after_deal: data.amount_after_deal,
                },
              ],
              product: data.products,
            },
            deal: {
              id: data.id,
              name: data.name,
              start_date: data.start_date,
              end_date: data.end_date,
              qty_item: data.qty_item,
              qty_bonus: data.qty_bonus,
            },
          };
        } else {
          addCartTemp = {
            from_package: false,
            qty: 1,
            plant: {
              id: this.paramBranchSLug,
            },
            product: null,
            package: {
              id: data.id,
              name: data.name,
              prices: [
                {
                  amount: data.prices[0].amount,
                },
              ],
            },
            deal: null,
          };
        }

        const indexCartTest = this.$store.state.cart.find(
          res => res.package === null
        );
        if (typeof indexCartTest !== "undefined") {
          this.$swal({
            text: this.$t("not_have_deal_cart"),
            icon: "warning",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: this.$t("yes"),
            cancelButtonText: this.$t("no"),
            showCancelButton: true,
            showCloseButton: true,
          }).then(result => {
            if (result.isConfirmed) {
              this.$store.state.cart.forEach(el => {
                this.removeCartAllPackage(el);
              });
              this.addPackageDeal(addCartTemp);
            }
          });
        } else {
          const indexCartSubs = this.$store.state.cart.find(
            res => res.package.subscription === false
          );
          if (typeof indexCartSubs !== "undefined") {
            this.$swal({
              text: this.$t("not_have_deal_cart"),
              icon: "warning",
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: this.$t("yes"),
              cancelButtonText: this.$t("no"),
              showCancelButton: true,
              showCloseButton: true,
            }).then(result => {
              if (result.isConfirmed) {
                this.$store.state.cart.forEach(el => {
                  this.removeCartAllPackage(el);
                });
                this.addPackageDeal(addCartTemp);
              }
            });
          } else {
            if (this.$store.state.cart.length > 0) {
              const indexCartNameDeal = this.$store.state.cart.find(
                res => res.package.name == data.name
              );
              if (typeof indexCartNameDeal !== "undefined") {
                this.removeCart(indexCartNameDeal.deal, data.id);
                this.addPackageDeal(addCartTemp);
              } else {
                if (typeof data.available_packages !== "undefined") {
                  const availablePackages = data.available_packages.find(
                    res => res.id == product_id
                  );
                  const indexCartNamePackage = this.$store.state.cart.find(
                    res => res.package.name == availablePackages.name
                  );
                  if (typeof indexCartNamePackage !== "undefined") {
                    this.removeCart(indexCartNamePackage, product_id);
                    this.addPackageDeal(addCartTemp);
                  } else {
                    if (
                      this.paramBranchSLug == this.$store.state.cart[0].plant.id
                    ) {
                      this.addPackageDeal(addCartTemp);
                    } else {
                      this.dialogConfirmation = true;
                      this.editedItem = addCartTemp;
                    }
                  }
                } else {
                  if (
                    this.paramBranchSLug == this.$store.state.cart[0].plant.id
                  ) {
                    this.addPackageDeal(addCartTemp);
                  } else {
                    this.dialogConfirmation = true;
                    this.editedItem = addCartTemp;
                  }
                }
              }
            } else {
              this.addPackageDeal(addCartTemp);
            }
          }
        }
      }
    },
    removeCart(param, param2) {
      if (param.has_deal) {
        this.$emit("openModalDeal", param);
      } else {
        this.$emit("removeCart", param, param2);
      }
    },
    addCartPackage(data) {
      if (this.checkBranch) {
        const addCartTemp = {
          from_package: false,
          qty: 1,
          plant: {
            id: this.paramBranchSLug,
          },
          product: null,
          package: {
            id: data.id,
            name: data.name,
            prices: [
              {
                amount: data.prices[0].amount,
              },
            ],
          },
          deal: null,
        };

        const indexCartTest = this.$store.state.cart.find(
          res => res.package === null
        );
        if (typeof indexCartTest !== "undefined") {
          this.$swal({
            text: this.$t("not_have_deal_cart"),
            icon: "warning",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: this.$t("yes"),
            cancelButtonText: this.$t("no"),
            showCancelButton: true,
            showCloseButton: true,
          }).then(result => {
            if (result.isConfirmed) {
              this.$store.state.cart.forEach(el => {
                this.removeCartAllPackage(el);
              });
              this.addPackageNonSubs(addCartTemp);
            }
          });
        } else {
          const indexCartSubs = this.$store.state.cart.find(
            res => res.package.subscription === true
          );
          if (typeof indexCartSubs !== "undefined") {
            this.$swal({
              text: this.$t("not_have_deal_cart"),
              icon: "warning",
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: this.$t("yes"),
              cancelButtonText: this.$t("no"),
              showCancelButton: true,
              showCloseButton: true,
            }).then(result => {
              if (result.isConfirmed) {
                this.$store.state.cart.forEach(el => {
                  this.removeCartAllPackage(el);
                });
                this.addPackageNonSubs(addCartTemp);
              }
            });
          } else {
            if (this.$store.state.cart.length > 0) {
              this.$swal({
                text: this.$t("not_have_deal_cart"),
                icon: "warning",
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: this.$t("yes"),
                cancelButtonText: this.$t("no"),
                showCancelButton: true,
                showCloseButton: true,
              }).then(result => {
                if (result.isConfirmed) {
                  this.$store.state.cart.forEach(el => {
                    this.removeCartAllPackage(el);
                  });
                  this.addPackageNonSubs(addCartTemp);
                }
              });
            } else {
              this.addPackageNonSubs(addCartTemp);
            }
          }
        }
      }
    },
    removeCartPackage(data) {
      const cart = this.$store.state.cart;
      const indexCart = cart.findIndex(res => res.package.id == data.id);
      cart.splice(indexCart, 1);
      const dataReady = [
        {
          plant_id: this.paramBranchSLug,
          package_id: data.id,
          deal_id: null,
          from_package: false,
          product_id: null,
          qty: 0,
        },
      ];
      API.post(
        `${process.env.VUE_APP_API_TRUST}cart/store`,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
        dataReady
      ).then(res => {
        if (res.statusCode == 200) {
          this.updateCartApi();
        }
      });
    },
    async updateCartApi() {
      await this.$store.dispatch("fetchCartData");
    },
    addPackageDeal(data) {
      var deal_id = null;
      if (data.deal != null) {
        deal_id = data.deal.id;
      }

      this.$store.commit("updateCart", [...this.$store.state.cart, data]);
      const dataReady = [
        {
          plant_id: data.plant.id,
          package_id: data.package.id,
          deal_id: deal_id,
          from_package: false,
          product_id: null,
          qty: 1,
        },
      ];
      API.post(
        `${process.env.VUE_APP_API_TRUST}cart/store`,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
        dataReady
      ).then(res => {
        if (res.statusCode == 200) {
          this.updateCartApi();
        }
      });
    },
    removeCartAllPackage(data) {
      var dataReady = [];
      if (data.deal === null && data.package === null) {
        dataReady = [
          {
            plant_id: data.plant.id,
            package_id: null,
            deal_id: null,
            from_package: false,
            product_id: data.product.id,
            qty: 0,
          },
        ];
      } else {
        if (data.deal === null) {
          dataReady = [
            {
              plant_id: data.plant.id,
              package_id: data.package.id,
              deal_id: null,
              from_package: false,
              product_id: null,
              qty: 0,
            },
          ];
        } else {
          dataReady = [
            {
              plant_id: data.plant.id,
              package_id: data.package.id,
              deal_id: data.deal.id,
              from_package: false,
              product_id: null,
              qty: 0,
            },
          ];
        }
      }
      API.post(
        `${process.env.VUE_APP_API_TRUST}cart/store`,
        // ?lang=${this.$i18n.locale}
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
        dataReady
      ).then(res => {
        if (res.statusCode == 200) {
          this.updateCartApi();
        } else if (res.statusCode == 403) {
          Object.keys(res.errors).forEach(element => {
            this.toast(
              "error",
              typeof res.errors[element] == "object"
                ? res.errors[element][0]
                : res.errors[element]
            );
          });
        } else {
          this.$swal({
            icon: "error",
            text: res.message,
            //text: this.$t("error_try_again_latter"),
          });
        }
      });
    },
    async getDetailPackage() {
      this.$store.commit("setLoading", true);
      try {
        const respList = await API.get(
          `${process.env.VUE_APP_API_TRUST}package/get/${
            this.$route.params.id || ""
          }?plant_id=${this.$route.query.plant || ""}`,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (respList.statusCode == 200) {
          const { results: listPackage } = respList;
          const respListTest = await API.get(
            `${process.env.VUE_APP_API_TRUST}product/get?package_id=${this.$route.params.id}`,
            {
              Authorization: `Bearer ${Auth.getToken()}`,
            }
          );
          const listTest = respListTest.results;
          const respRelated = await API.get(
            `${process.env.VUE_APP_API_TRUST}related-package/get?package_id=${listPackage[0].id}&plant_id=${this.$route.query.plant}`,
            {
              Authorization: `Bearer ${Auth.getToken()}`,
            }
          );
          this.detailPackage = {
            ...listPackage[0],
            list_test: listTest,
            related_package: respRelated.results,
          };
          this.defaultPrice = Object.prototype.hasOwnProperty.call(
            this.detailPackage,
            "prices"
          )
            ? this.detailPackage.prices[0].amount
            : 0;
        }

        this.$store.commit("setLoading", false);
      } catch (error) {
        console.log("Error 403!");
        this.$store.commit("setLoading", false);
      }
    },
    formatPrice(price) {
      let val = (price / 1).toFixed(2).replace(".", ".");
      return `IDR ${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    },
    async openDialog() {
      this.dialogMenu = true;
    },
    toggleDialogMenu(toggle) {
      this.dialogMenu = toggle;
    },
  },
};
</script>

<style lang="scss">
.test-list-package {
  .v-expansion-panel-content__wrap {
    padding: 0;
  }
}
</style>

<style lang="scss" scoped>
@import "../../../../assets/scss/color.scss";
.detail-package {
  padding: 24px 0 40px 0;
  min-height: 700px;
  overflow-x: hidden;
  a {
    text-decoration: none;
  }

  @media screen and (max-width: 960px) {
    background: white;
  }
}
@media screen and (max-width: 415px) {
  .btn-list {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
    margin: 10px;
  }
}

.price {
  color: #1f3c87;
  font-size: 14px !important;
}

.description {
  font-size: 12px;
  text-align: justify;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 26px;
  min-width: 120px;
  padding: 0 16px;
  color: $main_2;
}

.v-btn__content {
  color: $dark_blue_3;
}
.v-btn:not(.v-btn--round).v-size--default {
  height: 26px;
  width: 100%;
  padding: 0 16px;
  text-transform: capitalize;
  font-size: 12px;
  color: white;
  background-color: #00a4b3;
}

.theme--light.v-btn.v-btn--has-bg {
  background-color: $main_2;
}

.title-dialog {
  font-size: 12px;
  text-transform: uppercase;
  color: $main_2;
  font-weight: bold;
}

.content-dialog {
  text-align: justify;
  font-size: 12px;
  line-height: 16px;
  margin-top: -3px;
}

.theme--light.v-card > .v-card__text,
.theme--light.v-card > .v-card__subtitle {
  color: $dark_blue_3;
}

.accordion-dialog {
  margin-left: 22px;
  margin-right: 22px;
}
.paragraph-dialog {
  font-weight: 400;
  text-align: justify;
}
.closeButton {
  min-width: auto !important;
  position: absolute;
  right: 0;
  bottom: 15px;
}
.v-card__title {
  position: relative;
  padding-bottom: 0 !important;
}
.v-btn > .v-btn__content .v-icon {
  font-size: 15px;
}

.row-button {
  margin-top: -15px;
}

.button {
  color: white;
  width: 100%;
}

.detail-package {
  padding: 24px 0 40px 0;
  a {
    text-decoration: none;
  }
  .h-full {
    height: 100%;
    border: 1px solid #777777;
  }
}
.btn-close {
  width: auto !important;
  background: none !important;
  color: #00a4b3 !important;
}
//accordion
.header-title-acc {
  padding: 0 !important;
  margin-bottom: -20px !important;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  color: #00a4b3;
}
// .content-title-acc {
//   margin-left: -23px !important;
//   margin-right: -23px !important;
//   margin-top: -20px !important;
// }
.content-title-prep {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #1f3c87;
}
.line-title {
  margin-top: 35px;
}

.card--shadow {
  border: thin solid rgba(0, 0, 0, 0.12) !important;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
}

.btn-list {
  margin: 10px;
}

@media only screen and (max-width: 600px) {
  .card-top {
    position: sticky;
    top: 0;
    z-index: 999;
  }

  .notification {
    height: 40px;
    border-radius: 3px;
    margin-top: -7px;
    margin-left: -46px !important;
  }
  .cart {
    margin-left: 11px;
    margin-right: 3px;
  }

  .column-back {
    margin-right: 0px;
    margin-left: -20px;
    margin-top: -6px;
  }

  .back {
    cursor: pointer;
  }

  .text-field {
    max-width: 100% !important;
    margin-left: 4px !important;
    margin-top: -3px;
  }

  .cart-right {
    margin-left: -4px !important;
    z-index: 10;
  }

  .bell-mobile {
    margin-left: 8px;
  }
  .user-card {
    margin-top: -3px;
    max-width: 100% !important;
    margin-left: -10px;
    margin-right: -23px;
  }

  .price {
    font-size: 12px !important;
  }
}

.arrow-back {
  cursor: pointer;
}

.column-back {
  margin-top: -6px;
}

.text-field {
  max-width: 70%;
  margin-right: 4px !important;
  margin-left: -30px;
  margin-top: -3px;
}

.cart-right {
  text-align: right;
  justify-content: flex-end;
  background: none;
  width: 97%;
  margin-top: -18px;
  margin-left: 10px;
}
</style>
